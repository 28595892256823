<template>
  <!-- 提货管理 -->
  <div class="pick-up-goods-page-box">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :reset="true" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
        :operation-button="operationButton"
        :operation-button-width="192"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 签署确认弹窗 -->
    <PdfContract
      :sign-or-not="pdfContractConfig.signOrNot"
      :visible.sync="pdfContractConfig.visible"
      :url="pdfContractConfig.url"
    />
    <!-- 申请用印的弹窗 -->
    <!-- <Dialog
      ref="dialog"
      :dialog-width="'460px'"
      :edit-form-data="editFormData"
      :add-and-edit-form-item="addAndEditFormItem"
      @getFormData="getFormData"
    /> -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
// import Dialog from '@/components/Dialog'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import PdfContract from '@/components/PdfContract'
import { deliveryInfoPage } from '@/api/generationMining'
export default {
  components: { FormSearch, Table, Pagination, PdfContract },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      addAndEditFormItem: [{
        label: '审批类型',
        type: 'radio',
        child: [{ value: 1, label: '系统审批' }, { value: 2, label: '钉钉审批' }],
        value: 'oaType'
      }],
      pdfContractConfig: { visible: false, url: '' },
      itemData: [
        { label: '提货单编号', prop: 'deliveryNo', width: 180 },
        { label: '合同编号', prop: 'contractNo', width: 180 },
        { label: '经销商', prop: 'custCmpName', width: 180 },
        { label: '代采商', prop: 'fundsCmpName', width: 180 },
        { label: '合作企业', prop: 'supplierCmpName', width: 180 },
        { label: '交收仓库', prop: 'deliveryWarehouse', width: 120 },
        // { label: '提货金额(元)', prop: 'deliveryTotalPayment', width: 120 },
        { label: '代采费用(元)', prop: 'agentCost', width: 120 },
        // { label: '提货数量', prop: 'deliveryTotalQuantity', width: 120 },
        { label: '提货数量(吨)', prop: 'deliveryTotalWeight', width: 120 },
        // { label: '实提数量', prop: 'specs', width: 120 },
        // { label: '实提数量(吨)', prop: 'deliveryWeight', width: 120 },
        // { label: '申请日期', prop: 'applyDate', width: 160 },
        { label: '修改时间', prop: 'modifyTime', width: 160 },
        { label: '状态', prop: 'deliveryStatus', width: 100, child: this.$store.getters.getDictionaryItem('pick_up_goods_status') }
      ],
      formItemArr: [
        { type: 'input', label: '合同编号', value: 'contractNo' },
        { type: 'input', label: '经销商', value: 'custCmpName' },
        { type: 'input', label: '代采商', value: 'fundsCmpName' },
        { type: 'input', label: '仓库名称', value: 'deliveryWarehouse' },
        { type: 'select', label: '状态', value: 'deliveryStatus', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('pick_up_goods_status') }
      ],
      loading: false,
      total: 0,
      listData: [],
      operationButton: [
        // { num: 6, val: 'deliveryStatus', bType: 'primary', label: '签署确认', handleEvent: this.sign },
        // { num: 1, val: 'deliveryStatus', bType: 'primary', label: '申请用印', handleEvent: this.applicationSeals },
        // { num: 1, val: 'deliveryStatus', bType: 'danger', label: '拒绝申请', handleEvent: this.rejectSign },
        // { num: 6, val: 'deliveryStatus', bType: 'danger', label: '拒绝签署', handleEvent: this.rejectSign },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfo }
      ],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  created() {
    if (this.$route.query.contractNo) this.formInline.contractNo = this.$route.query.contractNo
  },
  methods: {
    // 用印申请的按钮点击
    // applicationSeals(row) {
    //   this.editFormData.id = row.id
    //   this.$refs.dialog.editFormVisible = true
    //   this.$refs.dialog.dialogTitle = '申请用印'
    // },
    // getFormData(value) {
    //   deliveryInfoSignAudit({ oaType: value.oaType, id: this.editFormData.id, contractStatus: 3 }, () => {
    //     this.$message.success('成功')
    //     this.getdata(true)
    //     this.$refs.dialog.editFormVisible = false
    //   })
    // },
    // 拒绝签署
    // rejectSign(row) {
    //   this.$prompt('请输入拒绝理由', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     inputValidator: (value) => {
    //       if (!value) {
    //         return '请输入'
    //       }
    //       if (value.length > 30) {
    //         return '拒绝理由不能超过30个字符'
    //       }
    //     }
    //   }).then(({ value }) => {
    //     deliveryInfoRefuseDeliveryBill({ id: row.id, deliveryStatus: row.deliveryStatus, refuseReason: value }, () => {
    //       this.getdata(true)
    //     })
    //   }).catch(() => {})
    // },
    // 确认签署
    // confirmSign() {
    //   deliveryInfoGetEsignUrl(this.pdfContractConfig.id
    //     , res => {
    //       this.$message.success('成功！')
    //       this.pdfContractConfig.visible = false
    //       window.open(res.data)
    //     })
    // },
    // 点击签署确认按钮
    sign(row) {
      this.pdfContractConfig = {
        visible: true,
        url: row.deliveryFileUrl,
        id: row.id
      }
      // deliveryInfoConfirmSign(row.id, res => {})
    },
    // 查看详情页面
    viewInfo(row) {
      this.$router.push({ path: '/pickUpGoods/pickUpGoodsDetails', query: { id: row.id }})
    },
    // 获取列表数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      deliveryInfoPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>
